<template>
  <div class="content">
    <div class="text-center family headtitle">创建对战</div>
    <div>
      <!-- 房间模式 -->
      <div>
        <div class="text-center family numtitle">房间模式</div>
        <div class="d-flex" style="justify-content: space-around;">
          <div
            class="family"
            @click="navRoll('lucky')"
            :class="navActive == 'lucky' ? 'peoplenums' : 'peoplenum'"
          >
            欧皇模式
          </div>
          <div
            class="family"
            style="pointer-events: none"
            @click="navRoll('ordinary')"
            :class="navActive == 'ordinary' ? 'peoplenum' : 'peoplenumout'"
          >
            非酋模式
          </div>
          <div
            class="family"
            style="pointer-events: none"
            @click="navRoll('team')"
            :class="navActive == 'team' ? 'peoplenum' : 'peoplenumout'"
          >
            组队模式
          </div>
        </div>
      </div>
      <!-- 房间人数 -->
      <div>
        <div class="text-center family numtitle">房间人数</div>
        <div class="d-flex" style="justify-content: space-around;">
          <div @click="userSum(item)" v-for="(item, index) in 3" :key="index">
            <div
              class="family"
              v-if="item == 1"
              :class="item == sum ? 'peoplenums' : 'peoplenum'"
            >
              双人模式
            </div>
            <div
              class="family"
              v-if="item == 2"
              :class="item == sum ? 'peoplenums' : 'peoplenum'"
            >
              三人模式
            </div>
            <div
              class="family"
              v-if="item == 3"
              :class="item == sum ? 'peoplenums' : 'peoplenum'"
            >
              四人模式
            </div>
          </div>
        </div>
      </div>
      <!-- 房间类型 -->
      <div>
        <div class="text-center family numtitle">房间类型</div>
        <div class="d-flex boxtype" style="justify-content: space-around;">
          <div
            class="family"
            @click="navRolls('newpeople')"
            :class="navActives == 'newpeople' ? 'peoplenums' : 'peoplenum'"
          >
            新手入门
          </div>
          <div
            class="family"
            style="pointer-events: none"
            @click="navRolls('intermediateople')"
            :class="
              navActives == 'intermediateople' ? 'peoplenums' : 'peoplenum'
            "
          >
            中级战场
          </div>
          <div
            class="family"
            style="pointer-events: none"
            @click="navRolls('senior')"
            :class="navActives == 'senior' ? 'peoplenums' : 'peoplenum'"
          >
            高级战斗
          </div>
          <div
            class="family"
            style="pointer-events: none"
            @click="navRolls('ultimate')"
            :class="navActives == 'ultimate' ? 'peoplenums' : 'peoplenum'"
          >
            刺激专场
          </div>
        </div>
      </div>
      <!-- 盲盒选择 -->
      <div>
        <div class="text-center family numtitle">盲盒选择</div>
        <div class="family" style="text-align: right; padding-right: 10px">
          最多选择6个盲盒
        </div>
        <!-- 盲盒 -->
        <div class="list">
          <!-- <div class="lists" v-for="item in boxGunList" :key="item.id"> -->
          <div
            class="lists"
            v-for="(item, index) in boxGunList"
            :key="index"
            :class="item.active ? 'active' : ''"
          >
            <div class="listName">
              <!-- <span>CS：GO10周年印花胶囊</span> -->
              <span style="font-size: 8px">{{ item.name }}</span>
            </div>
            <img :src="item.intact_cover" alt="" width="100px" height="80px" />
            <div class="listBtm" style="font-size: 12px">
              <img src="@/assets/image/userCenter/u8_icon.png" alt="" />
              <span style="font-size: 8px">{{ item.game_bean }}</span>
            </div>
            <!-- 添加 -->
            <div @click="ChooseWeaponsClick(item, index)" class="addbox"></div>
          </div>
        </div>
        <div class="d-flex" style="margin-top: 20px; font-size: 14px">
          <div>已选择箱子 ({{ boxGunListPitch.length }}/6)</div>
          <div>
            总金额：<span class="font-weight-bold"> {{ money }}</span>
          </div>
        </div>
      </div>
      <!-- 添加箱子 -->
      <div class="list">
        <div
          class="lists"
          v-for="(item, index) in boxGunListPitch"
          :key="index + 'box'"
        >
          <div class="listName">
            <span style="font-size: 8px">{{ item.name }}</span>
          </div>
          <img width="100px" height="80px" :src="item.intact_cover" alt="" />
          <div class="listBtm">
            <img src="@/assets/image/userCenter/u8_icon.png" alt="" />
            <span style="font-size: 8px">{{ item.game_bean }}</span>
          </div>
          <div @click="deleteGun(item, index)" class="" style="">
            <div>
              <i
                class="iconfont text-white el-icon-error"
                style="font-size: 30px"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="boxbottom">
        <!-- <div class="">
          回合数：<span class=""> {{ boxGunListPitch.length }}</span>
        </div> -->
        <div class="boxbottom-box">
          <div
            @click="getPKarenaCreate"
            style="font-size: 14px"
            class="boxbottom-bnt"
          >
            创建竞技场
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="">
      <div style="" class="">
        <div class="text-center">
          <div @click="ChooseWeaponsAdd" style="width: 150px" class="">
            完成
          </div>
        </div>
      </div>

      <div @click="ChooseWeaponsAdd"></div>
    </div> -->
  </div>
</template>

<script>
import $api from "@/api/pk/index";
export default {
  name: "creationHome",
  data() {
    return {
      sum: 1,
      boxGunAdd: false,
      lockBoxGunIsShow: false,
      boxGunList: [],
      boxGunListPitch: [],
      boxGunListContains: [],
      navActive: "lucky",
      navActives: "newpeople",
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    addGunLingth() {
      return 6 - this.boxGunListPitch.length;
    },
    money() {
      let bean = 0;
      this.boxGunListPitch.forEach((item) => {
        bean += item.game_bean * 1;
      });
      return bean.toFixed(2);
    },
  },
  methods: {
    init() {
      this.getPKarenaboxList();
    },
    userSum(item) {
      document.getElementById("mysound").play();
      this.sum = item;
    },
    ChooseWeapons() {
      this.boxGunAdd = true;
      document.getElementById("mysound").play();
    },
    async getPKarenaboxList() {
      try {
        let { data, code } = await $api.getPKarenaboxList();
        if (code == 500) return;
        this.boxGunList = data.map((item) => {
          item.active = false;
          return item;
        });
      } catch (e) {
        console.log(e);
      }
    },
    ChooseWeaponsClick(item) {
      document.getElementById("mysound").play();
      if (this.boxGunListPitch.length > 5) {
        this.$notify({
          message: "最多添加6个箱子",
          position: "top-left",
        });
        return;
      }
      this.boxGunList.forEach((t) => {
        if (item.id == t.id) {
          this.boxGunListPitch.push(item);
        }
      });
    },
    ChooseWeaponsAdd() {
      document.getElementById("mysound").play();
      this.boxGunListPitch = JSON.parse(JSON.stringify(this.boxGunListPitch));
      this.boxGunAdd = false;
    },
    deleteGun(item, index) {
      document.getElementById("mysound").play();
      this.boxGunListPitch.splice(index, 1);
    },
    async getPKarenaCreate() {
      document.getElementById("mysound").play();
      try {
        let boxId = this.boxGunListPitch.map((item) => {
          return item.id;
        });
        let create = {
          user_num: this.sum + 1,
          box: boxId,
        };
        let { code, data } = await $api.getPKarenaCreate(create);
        if (code == 500) return;
        if (code == 200) {
          this.$message.success("创建成功");
          setTimeout(() => {
            this.$router.push("/active/mysteryBox/" + data.id);
          }, 2000);
        }
      } catch (e) {
        console.log(e);
        return;
      }
    },
    lockBoxGun(item) {
      this.lockBoxGunIsShow = true;
      this.boxGunListContains = item.contains;
    },
    navRoll(state) {
      this.navActive = state;

      if (state == "lucky") {
        return;
      }
      if (state == "ordinary") {
        return;
      }
      if (state == "team") {
        return;
      }
    },
    navRolls(states) {
      this.navActives = states;
      if (states == "newpeople") {
        return;
      }
      if (states == "intermediate") {
        return;
      }
      if (states == "senior") {
        return;
      }
      if (states == "ultimate") {
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  .headtitle {
    font-size: 35px;
    color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 2px #98d462;
    margin: 15px 0;
  }
  div {
    div {
      .numtitle {
        font-size: 25px;
        color: rgba(0, 0, 0, 0);
        -webkit-text-stroke: 2px #98d462;
        margin: 10px 0;
      }
      div {
        align-items: flex-end;
        font-size: 16px;
        .peoplenum {
          width: 100px;
          height: 45px;
          background-image: url("../../assets/imgV2/roll/bgimg.png");
          text-align: center;
          line-height: 50px;
          background-size: 100%;
          background-repeat: no-repeat;
          color: #039ef6;
        }
        .peoplenums {
          width: 100px;
          height: 45px;
          background-image: url("../../assets/imgV2/roll/bgimgs.png");
          text-align: center;
          line-height: 50px;
          background-size: 100%;
          background-repeat: no-repeat;
          color: #9dda3c;
        }
        .peoplenumout {
          width: 100px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          color: rgb(0, 0, 0);
          border-radius: 40px;
          box-shadow: 0 0 0 3px black inset;
        }
      }
      .boxtype {
        width: 80%;
        margin: auto;
        flex-wrap: wrap;
        div {
          width: 39%;
          height: 50px;
          line-height: 60px;
        }
      }
    }
  }
}
.list {
  width: 100%;
  // height: 150px;
  overflow: auto;
  margin-top: 25px;
  display: flex;
  

  .lists {
    // width: 100px;
    height: 160px;
    background: linear-gradient(180deg, #9fda35 0%, rgba(159, 218, 53, 0) 100%);
    border-radius: 13px 13px 13px 13px;
    opacity: 1;
    margin-right: 10px;
    text-align: center;

    .listName {
      width: 80px;
      margin-left: 5px;
    }

    .img {
      margin: 5px auto;
      width: 80px;
      height: 80px;
      background: #ffffff;
      border-radius: 13px 13px 13px 13px;
      opacity: 1;
    }

    .listBtm {
      img {
        width: 20px;
        height: 20px;

        position: relative;
        top: 4px;
      }
    }
  }
}
.addbox {
  background-color: transparent;
  width: 100%;
  height: 150px;
  position: relative;
  top: -116px;
  border-radius: 15px;
}
.boxbottom {
  margin: 30px 0;
}
.boxbottom-bnt {
  height: 50px;
  width: 200px;
  margin: auto;
  text-align: center;
  border-radius: 30px;
  line-height: 50px;
  background-color: #88c23e;
  box-shadow: 0 0 0 3px #05377b inset;
}
.list::-webkit-scrollbar {
    display: none;
  }
</style>
